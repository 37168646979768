import Splash from "common/Splash";

function App() {

  return (
    <Splash />
  );
}

export default App;
