import React, { useState, useEffect } from 'react';
import pb from 'common/lib/pocketbase';

import NewNav from 'common/NewNav';

import 'App.css';
import image from "assets/lifeguard.jpg";

const authState = JSON.parse(localStorage.getItem('pocketbase_auth'));
const localProvider = JSON.parse(localStorage.getItem('provider'));

function quickTest() {
    window.location.assign("/guest.html");
}

function Bold({ text }) {
    return (
        <p><strong>{text}</strong></p>
    );
};

function SplashCard() {
    return (
        <div className="center">
            <article className="description">
                <h3>
                    Καλωσήρθατε στο LifeguardQuiz!
                </h3>
                <Bold text="Εξασκηθείτε στις ερωτήσεις των εξετάσεων ανά κατηγορία." />
                <hr />
                <Bold text="Δείτε συγκεντρωμένα τα λάθη σας ανά κατηγορία." />
                <hr />
                <Bold text="Κάντε προσομοίωση των εξετάσεων σας." />
            </article>
        </div>
    );
};

function GoogleCard() {
    const redirectUrl = process.env.REACT_APP_PB_REDIRECT_URL;
    const [state, setState] = useState("none");
    const [provider, setProvider] = useState(null);
    const [authUrl, setAuthUrl] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
        setState('loading');
        pb.collection('users').listAuthMethods()
            .then((providers) => {
                setState("success");
                setProvider(providers.authProviders[0]);
                setAuthUrl(providers.authProviders[0].authUrl);
            })
            .catch((error) => {
                setState("error");
                setError(error);
            });
    }, []);

    if (state === 'error') {
        if (!error.toString().includes("autocancelled")) {
            console.log(error.toString());
        }
        return (
            <></>
        );
    };

    const handleClick = (e) => {
        const newUrl = authUrl + redirectUrl;
        localStorage.setItem('provider', JSON.stringify(provider));
        window.location.assign(newUrl);
    }

    return (
        <div className="center google">
            <article className="no-select">
                <img src={image} alt="lifeguard with floater" />
                <button onClick={handleClick}>
                    Σύνδεση μέσω Google
                </button>
            </article>
        </div>
    );
};

function Splash() {
    // Check if authData is stored
    try {
        authState.token.toString();
        window.location.assign("/home.html");
    } catch (error) {
        ;
    }
    if (!localProvider) {
        ;
    }
    return (
        <>
            <NewNav />
            <main className="container splash">
                <div className="grid">
                    <SplashCard />
                    <GoogleCard />
                </div>
                <hr />
                <div className="quick center">
                    <strong>
                        Εξασκηθείτε χωρίς σύνδεση
                    </strong>
                    <div>
                        <button className="secondary outline" onClick={quickTest}>
                            Γρήγορο Test
                        </button>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Splash;