import 'App.css';
import pb from 'common/lib/pocketbase';

import logo from "assets/logo192.png";

function logOut() {
    pb.authStore.clear();
    window.location.assign("/");
}

function NavLogo() {
    return (
        <ul className="no-select">
            <li>
                <a href={pb.authStore.isValid ? "/home.html": "/"}>
                    <img src={logo} alt="logo" />
                </a>
            </li>
            <li>
                <strong>
                    <a href={pb.authStore.isValid ? "/home.html": "/"}>LifeguardQuiz</a>
                </strong>
            </li>
        </ul>
    );
};

function NewNav() {
    const newTest = pb.authStore.isValid ? "/quiz.html" : "/guest.html";
    return (
        <div className="navbar container-fluid">
            <nav>
                <NavLogo />
                <ul>
                    {/* <li><a href="#sd">Profile</a></li> */}
                    <li><a role="button" className='outline secondary' href={newTest}>Νέο Test</a></li>
                    {pb.authStore.isValid &&
                        <li><a href="#logout" role="button" className='outline secondary' onClick={logOut}>Logout</a></li>
                    }
                </ul>
            </nav>
        </div>
    );
};

export default NewNav;

